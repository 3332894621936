<template>
    <div class="view-control-position-on-drawing">
        <div style="overflow: hidden">
            <div class="drawing--actions-buttons-group-top">
                <div class="mb-2">
                    <b-button-group size="sm" class="mr-2">
                        <button class="btn btn-sm btn-light btn-outline-gray-light rounded-0"
                                @click="onZoomOut"
                                title="Zooma ut ritning">
                            <font-awesome-icon icon="search-minus" class="text-info" />
                        </button>
                        <button class="btn btn-sm btn-light btn-outline-gray-light rounded-0"
                                @click="onZoomIn"
                                title="Zooma in ritning">
                            <font-awesome-icon icon="search-plus" class="text-info" />
                        </button>
                    </b-button-group>

                    <b-button-group size="sm" class="ml-2 mr-2">
                        <b-button variant="light" class="border-gray-light rounded-0" @click="changePositionScale(-0.1)" title="Mindre symboler">-</b-button>
                        <b-button variant="light" class="border-gray-light" disabled>{{symbolSize}}</b-button>
                        <b-button variant="light" class="border-gray-light rounded-0" @click="changePositionScale(0.1)" title="Större symboler">+</b-button>
                    </b-button-group>

                    <b-form-radio-group v-model="symbolSize"
                                        :options="scales"
                                        size="sm"
                                        class="ml-2 border-outline-gray-light"
                                        button-variant="light"
                                        buttons
                                        style="max-height: 31px;">
                    </b-form-radio-group>
                    <small class="ml-4">Justera in så ritning syns. <i>Denna panel kommer ej synas vid utskrift</i></small>
                </div>
            </div>
            <div style="background-color: #fff;" class="fit-to-page">
                <panZoom ref="svgDrawingPanZoom"
                         :options="{
                                        minZoom: 0.1,
                                        maxZoom: 3,
                                        smoothScroll: false,
                                        initialZoom: zoom
                                     }"
                         @panstart="onPanStart"
                         @panend="onPanEnd"
                         style="overflow: hidden;">
                    <svg ref="box"
                         xmlns="http://www.w3.org/2000/svg"
                         version="1.1"
                         :viewBox="`0 0 ${boxSize.width} ${boxSize.height}`">
                        <!--Drawing-->
                        <image :xlink:href="part.partDrawingAsBase64"
                               height="100%"
                               width="100%" />

                        <g v-for="(items, key) in filteredAndGroupedPositionsByNumber"
                           :key="key">
                            <g v-if="items.length > 1">
                                <!--Line between icon and dot-->
                                <line :x1="items[0].coordinateX"
                                      :y1="items[0].coordinateY"
                                      :x2="items[0].coordinateInfoX"
                                      :y2="items[0].coordinateInfoY"
                                      :stroke-width="positionSize.line"
                                      stroke="black" />
                                <!--Grey dot-->
                                <circle :cx="items[0].coordinateX"
                                        :cy="items[0].coordinateY"
                                        :r="positionSize.radiePoint"
                                        fill="grey" />
                                <!--Icon-->
                                <g>
                                    <!--Rect-->
                                    <rect :width="positionSize.width"
                                          :height="positionSize.height"
                                          :x="items[0].coordinateInfoX - positionSize.width / 2"
                                          :y="items[0].coordinateInfoY - positionSize.width / 2"
                                          :fill="getGroupStatusColor(items)" />
                                    <text :x="items[0].coordinateInfoX"
                                          :y="items[0].coordinateInfoY"
                                          text-anchor="middle"
                                          :font-size="items[0].number >= 100 ? positionSize.fontSizeSmall : positionSize.fontSize"
                                          :alignment-baseline="items[0].number >= 100 ? 'middle' : 'middle'"
                                          :dy="items[0].number >= 100 ? '-.4em' : '0.5px'"
                                          fill="white">
                                        {{ items[0].number }}
                                    </text>
                                </g>
                            </g>

                            <!--Icon Start-->
                            <g v-for="item in items"
                               v-else
                               :key="item.id">
                                <g>
                                    <!--Line between icon and dot-->
                                    <line :x1="item.coordinateX"
                                          :y1="item.coordinateY"
                                          :x2="item.coordinateInfoX"
                                          :y2="item.coordinateInfoY"
                                          :stroke-width="positionSize.line"
                                          stroke="black" />
                                    <!--Grey dot-->
                                    <circle :cx="item.coordinateX"
                                            :cy="item.coordinateY"
                                            :r="positionSize.radiePoint"
                                            fill="grey" />
                                    <!--Icon-->
                                    <g>
                                        <!--Circle-->
                                        <circle :r="positionSize.radieCircle"
                                                :cx="item.coordinateInfoX"
                                                :cy="item.coordinateInfoY"
                                                :fill="getStatusColor(item.statusId)" />
                                        <text :x="item.coordinateInfoX"
                                              :y="item.coordinateInfoY"
                                              text-anchor="middle"
                                              :alignment-baseline="items[0].number >= 100 ? 'middle' : 'middle'"
                                              :font-size="item.number >= 100 ? positionSize.fontSizeSmall : positionSize.fontSize"
                                              :dy="item.number >= 100 ? '+.1em' : '0.1em'"
                                              fill="white">
                                            {{ item.number }}
                                        </text>
                                    </g>
                                    <font-awesome-icon v-if="item.hasLackFromOtherControl"
                                                       icon="exclamation-triangle"
                                                       :x="item.coordinateInfoX - positionSize.width * 0.75"
                                                       :y="item.coordinateInfoY - positionSize.height * 0.75"
                                                       style="color:red;"
                                                       :height="positionSize.height/2"
                                                       :width="positionSize.width/2" />
                                </g>
                            </g>
                        </g>
                    </svg>
                </panZoom>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        getStatusColor,
        getGroupStatusColor
    } from '@/services/control-position-status.service';
    import {
        getPositionSizeObject,
        SCALES
    } from '@/helpers/position-symbol.helper';
    import Loader from '@/components/Loader';
    import ControlPositionStatusBall from '@/components/control/ControlPositionStatusBall';

    export default {
        name: 'ViewControlPositionsOnDrawing',
        components: {
            Loader,
            ControlPositionStatusBall
        },
        props: {
            part: Object,
            positions: {
                type: Array,
                required: true
            },
            initialZoom: {
                type: Number,
                required: false,
                default: 1
            }
        },
        data() {
            return {
                posBackgroundColor: '#fff',
                zoom: this.initialZoom,
                isPanning: false,
                boxSize: {
                    width: 500,
                    height: 400
                },
                symbolSize: this.part.symbolSize,
                scales: SCALES
            };
        },
        computed: {
            positionSize() {
                return getPositionSizeObject(this.symbolSize);
            },
            filteredAndGroupedPositionsByNumber() {
                return this.positions
                    .filter((x) => x.controlPartId === this.part.id)
                    .groupByKey('number');;
            }
        },
        methods: {
            getStatusColor(statusId) {
                return getStatusColor(statusId);
            },
            getGroupStatusColor(positions) {
                return getGroupStatusColor(positions);
            },
            onZoomIn() {
                const instance = this.$refs.svgDrawingPanZoom.$panZoomInstance;
                const current = instance.getTransform();
                instance.smoothZoom(current.x, current.y, 1.5);
            },
            onZoomOut() {
                const instance = this.$refs.svgDrawingPanZoom.$panZoomInstance;
                const current = instance.getTransform();
                instance.smoothZoom(current.x, current.y, 0.75);
            },
            onPanStart(event) {
                this.isPanning = true;
            },
            onPanEnd(event) {
                this.isPanning = false;
            },
            getStatusColor(pos) {
                return getStatusColor(pos);
            },
            changePositionScale(change) {
                let scale = Math.round((this.symbolSize + change) * 10) / 10
                if (scale <= 0) scale = 0.1;
                if (scale > 3) scale = 3;
                this.setPartSymbolSize(scale);
            },
            setPartSymbolSize(newSize) {
                this.symbolSize = newSize;
            }
        }
    };
</script>

<style lang="scss">
    .view-control-position-on-drawing .drawing--actions-buttons-group-top {
        z-index: 10;
        position: relative;
        width: 100%;
        text-align: center;
        background-color: #fff;
    }

    .fit-to-page {
        width: calc(297mm - 40mm);
        height: calc(210mm - 50mm)!important;
        overflow:hidden;
    }
</style>
