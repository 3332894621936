<template>
    <div>
        <div v-if="control">
            <b-row class="no-print"
                   v-if="activePartId">
                <b-col cols="12">
                    <b-button @click="openPrintDialog"
                              variant="primary"
                              class="float-right">
                        <font-awesome-icon icon="print" /> Skriv ut
                    </b-button>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <h3>{{ control ? control.serialNumber + ' - ' + control.scheduleName : '' }}</h3>
                </b-col>
                <b-col cols="6">
                    <h6><strong>Fastighet:</strong> {{ control ? control.buildingName : '' }}</h6>
                    <h6 v-if="activePartId"><strong>Del:</strong> {{ activePart.partName ? activePart.partName : '' }}</h6>
                </b-col>
                <b-col cols="6">
                    <h6><strong>Ansvarig:</strong> {{ control.userFullName }}</h6>
                    <h6 v-if="control.doneDate"><strong>Avslutades:</strong> {{ control.doneDate | datetime('yyyy-MM-dd')}}</h6>
                </b-col>
            </b-row>
        </div>
        <div>
            <b-table :items="positionsByPart"
                     :fields="fields"
                     stacked="sm"
                     show-empty
                     fixed
                     :busy="isLoading"
                     small>
                <template #cell(concatenatedNumbers)="row">
                    <div class="overflow-hidden">
                        {{ row.item.concatenatedNumbers }}
                    </div>
                </template>
                <!--<template #cell(articleName)="row">
        <div class="overflow-hidden">
            <img v-if="row.item.fileSymbolId"
                 style="height: 1.5rem"
                 :src="getUrl(row.item.articleId)" />
            {{ row.item.articleName }}
        </div>
    </template>-->
                <template #cell(description)="row">
                    <div class="overflow-hidden">
                        {{ row.item.description }}
                    </div>
                </template>
                <template #cell(buildingPartName)="row">
                    <div class="overflow-hidden">
                        {{ row.item.buildingPartName }}
                    </div>
                </template>
                <template #cell(place)="row">
                    <div class="overflow-hidden">
                        {{ row.item.place }}
                    </div>
                </template>
                <template #cell(service)="row">
                    <div class="overflow-hidden" v-if="row.item.nextRevisionDate">
                        {{ row.item.nextRevisionDate | datetime('yyyy') }}
                    </div>
                </template>
                <template #cell(brist)="row">
                    <b-form-checkbox :checked="row.item.statusId > 0 && row.item.statusId < 10"
                                     disabled />
                </template>
                <template #cell(ok)="row">
                    <b-form-checkbox :checked="row.item.statusId >= 10"
                                     disabled />
                </template>
                <template #row-details="row">
                    <b-row>
                        <b-col>
                            <p class="mb-0"><strong>Anm:</strong> {{ row.item.lackDescription }}</p>
                        </b-col>
                    </b-row>
                </template>
                <template #table-busy>
                    <div class="text-center mt-4">
                        <font-awesome-icon class="align-middle"
                                           icon="spinner"
                                           size="2x"
                                           spin /> Förbereder utskrift...
                    </div>
                </template>
                <template #empty="">
                    <h4 class="text-center">Det finns inga positioner</h4>
                </template>
            </b-table>
        </div>
        <!--Notes.-->
        <div>
            <b-row v-if="control">
                <b-col cols="12">
                    <hr />
                    <strong>Anteckningar: </strong>{{!control.notes ? '-' : ''}}
                    <p style="overflow-wrap: anywhere;">{{control.notes}}</p>
                </b-col>
            </b-row>
        </div>
        <!--Drawing.-->
        <b-row v-if="activePartId && !isLoading && activePart.partDrawingAsBase64 !== ''"
               class="page-break-before">
            <b-col cols="12">
                <h3>Kontrollpositioner</h3>
                <ViewControlPositionsOnDrawing :positions="positionsByPart"
                                               :part="activePart" />
            </b-col>
        </b-row>
        <div class="footer no-print">

        </div>
    </div>
</template>

<script>
    import { get } from '@/helpers/api';
    import {
        getDownloadUrlByArticleId
    } from '@/helpers/file.helper';
    import ViewControlPositionsOnDrawing from '@/components/control/ViewControlPositionsOnDrawing';

    export default {
        name: 'ControlPrintView',
        watch: {
            isLoading(newValue) {
                // Öppnar print ev. dialog.
                if (!newValue && !this.activePartId) {
                    this.$nextTick(() => {
                        this.openPrintDialog();
                    });
                }
            }
        },
        components: {
            ViewControlPositionsOnDrawing
        },
        computed: {
            id() {
                return typeof this.$route.params.controlId !== 'undefined'
                    ? parseInt(this.$route.params.controlId)
                    : null;
            },
            activePartId() {
                return typeof this.$route.params.partId !== 'undefined'
                    ? parseInt(this.$route.params.partId)
                    : null;
            },
            activePart() {
                return this.activePartId
                    ? this.control.parts.find(x => x.id === this.activePartId)
                    : null;
            },
            positionsByPart() {
                if (!this.control)
                    return [];
                return this.activePartId
                    ? this.control.positions.filter(x => x.controlPartId === this.activePartId)
                    : this.control.positions;
            },
            fields() {
                if (this.isLoading)
                    return this.defaultFields;

                let fields = this.defaultFields;

                if (!this.control.isService)
                    fields = fields.filter(x => x.key !== 'service');
                
                if (this.activePartId)
                    fields = fields.filter(x => x.key !== 'buildingPartName');

                return fields;
            }
        },
        data() {
            return {
                isLoading: true,
                control: null,
                defaultFields: [{
                    key: 'concatenatedNumbers',
                    label: '#',
                    thStyle: { 'width': '70px' }
                },
                {
                    key: 'articleName',
                    label: 'Artikel'
                },
                {
                    key: 'description',
                    label: 'Beskrivning'
                }, {
                    key: 'buildingPartName',
                    label: 'Del'
                }, {
                    key: 'place',
                    label: 'Plats'
                }, {
                    key: 'service',
                    label: 'Service',
                    thStyle: { 'width': '80px' }
                }, {
                    key: 'brist',
                    label: 'Brist',
                    thStyle: { 'width': '45px' }
                }, {
                    key: 'ok',
                    label: 'OK',
                    thStyle: { 'width': '35px' }
                }]
            }
        },
        methods: {
            openPrintDialog() {
                window.print();
            },
            getUrl(id) {
                return getDownloadUrlByArticleId(id);
            },
            async getControl() {
                await get('Control', `Get/${this.id}`)
                    .then((x) => {
                        x.data.positions = x.data.positions.map(x => { return { ...x, _showDetails: x.lackDescription } })
                        this.control = x.data;
                        this.isLoading = false;
                    });
            }
        },
        async beforeMount() {
            await this.getControl();
        }
    };
</script>

<style>
    @media print {
        @page {
            size: landscape;
            margin: 10mm;
        }

        /* will this work? */
        @page:footer {
            display: none;
        }

        @page:header {
            display: none;
        }
        /* ./ will this work? */

        .drawing--actions-buttons-group-top {
            display: none;
        }

        .no-print {
            display: none;
        }
    }

    .footer {
        height: 200px;
    }

    .page-break-before {
        page-break-before: always;
    }

    .v-sidebar-menu {
        display: none;
    }

    .sidebar-menu-mobile--toggle-button-wrapper {
        display: none;
    }



    #app {
        padding-left: 5px !important;
    }
</style>