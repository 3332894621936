'use strict';

export const SCALES = [
    { text: 'S', value: 0.3, title: 'Små symboler' },
    { text: 'M', value: 0.6 },
    { text: 'L', value: 1 },
    { text: 'XL', value: 1.3 }
];

export const getPositionSizeObject = function(symbolSize) {
    return {
        width: 20 * symbolSize,
        height: 20 * symbolSize,
        radieCircle: 10 * symbolSize,
        radiePoint: 2.5 * symbolSize,
        radieStatus: 4 * symbolSize,
        fontSizeSmall: 10 * symbolSize + 'px',
        fontSize: 12 * symbolSize + 'px',
        line: 1 * symbolSize
    };
};